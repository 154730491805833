import React, { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Stage } from '@react-three/drei'
import Model from './Model'

export default function Viewer() {
  const ref = useRef()
  const [texture, setTexture] = useState()
  const [uploads, setUploads] = useState([])
  const [selectedUpload, setSelectedUpload] = useState()
  const [menuOpened, setMenuOpened] = useState(false)

  const loadImage = (file) => {
    return new Promise((resolve) => {
      const image = new Image()

      image.onload = () => {
        resolve(image)
      }

      image.src = file
    })
  }

  const handleUploadSelect = async (e) => {
    e.preventDefault()

    const img = await loadImage(e.target.href)
    const data = img.src

    setSelectedUpload(e.target.dataset.id)
    setTexture(data)

    window.history.replaceState(null, '', `?can=${e.target.dataset.id}`)
  }

  useEffect(() => {
    const handleDrop = async (e) => {
      e.preventDefault()

      const dt = e.dataTransfer
      const files = dt.files

      if (files.length) {
        const img = await loadImage(window.URL.createObjectURL(files[0]))
        const data = img.src

        setSelectedUpload(null)
        setTexture(data)
      }
    }

    function preventDefaults (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
      document.body.addEventListener(eventName, preventDefaults, false)
    })

    document.body.addEventListener('drop', handleDrop, false)
  }, [setTexture])

  useEffect(() => {
    fetch('/scan.php')
      .then(res => res.json())
      .then(
        (result) => {
          setUploads(result)

          const urlParams = new URLSearchParams(window.location.search)
          const selectedUploadId = urlParams.get('can')

          if (selectedUploadId) {
            const selectedUpload = result.find(x => x.id === selectedUploadId)

            if (selectedUpload) {
              const setImage = async () => {
                const img = await loadImage(selectedUpload.path)
                const data = img.src

                setSelectedUpload(selectedUploadId)
                setTexture(data)
              }

              setImage()
            }
          }
        },
        (error) => {
          console.error(error)
        }
      )

    document.body.addEventListener('click', (e) => {
      if (e.target.tagName.toLowerCase() === 'canvas') {
        setMenuOpened(false)
      }
    })
  }, [])

  return (
    <>
      {uploads.length > 0 ? (
        <>
          <button type="button" className="upload-selector-btn" onClick={() => setMenuOpened(!menuOpened)}>
            <svg viewBox="0 0 24 24">
              <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
          </button>

          <div className={`upload-selector-menu ${menuOpened ? 'is-opened' : ''}`}>
            <ul>
              {uploads.map((item) => (
                <li key={item.id} className={`${selectedUpload === item.id ? 'is-active' : ''}`}>
                  <a href={`${item.path}`} onClick={handleUploadSelect} data-id={item.id}>{item.label}</a>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}

      <Canvas shadows={false} dpr={[0, 2]} camera={{ fov: 45 }}>
        <ambientLight intensity={0.2} />
        <pointLight position={[-10, 0, 0]} intensity={.5} color="white" />
        <pointLight position={[10, 10, 0]} intensity={1} color="white" />

        <Suspense fallback={null}>
          <Stage contactShadow={false} shadows={false} contactShadowOpacity={0} controls={ref} preset="rembrandt" intensity={0}  environment="studio">
            <Model texture={texture} />
          </Stage>
        </Suspense>
        <OrbitControls ref={ref} />
      </Canvas>
    </>
  )
}
